import { toBoolean } from '@global/utils/boolean/to-boolean';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { DigitalTreatment, linkTo199 } from '@lp-root/src/modules/subscribe/payment-options';
import SubscribePageTemplate from '@lp-root/src/modules/subscribe/subscribe-page-template';
import { PageProps } from '@lp-src/utils/local-types';
import { Col, Row } from '@web/atomic';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { NativeHelper } from '@web/data/native.helper';
import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';

interface AssinaturaAppPageQueryParams {
  sku: string;
  price: string;
  priceOld?: string;

  skuUrl?: string;

  // WHY would we skip IAP?
  // https://www.notion.so/vigilantesdosono/Apple-rejection-coupons-224b7d8cdd1c4c96a49d0779c395e42c#1129b8fc84244b14be13d70acc3ba9f1
  skipIAP?: 'true';
  freeTrial?: 'true';
  autoRenewable?: 'true';
}

const AssinaturaAppPage: React.FunctionComponent<PageProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const queryParams = useQueryParams<AssinaturaAppPageQueryParams>();
  const handleSubscribeClick = () => {
    const sku = queryParams.sku;
    if (!NativeHelper.isNative || !sku || toBoolean(queryParams.skipIAP)) {
      setLoading(true);
      const url = queryParams.skuUrl || linkTo199;
      if (url.includes(location.origin)) {
        navigate(url.replace(location.origin, ''));
      } else {
        window.open(url);
        setTimeout(() => {
          if (setLoading) setLoading(false);
        }, 10000);
      }
    } else {
      NativeHelper.postMessage({
        type: 'subscribe',
        data: { sku },
      });
    }
  };

  return (
    <WebviewLayout hideThemeChanger>
      <SubscribePageTemplate
        title={'Acesso ao programa completo'}
        location={props.location}
        siteUrl={props.data.site.siteMetadata.siteUrl}
        hideFreeTrial={true}
      >
        <Row center="xs">
          <Col xs={12} sm={10} md={7} mb>
            <DigitalTreatment
              loading={loading}
              onClick={handleSubscribeClick}
              price={queryParams.price || '199,99'}
              recurrencePeriod={toBoolean(queryParams.autoRenewable) ? ' /ano' : ''}
              priceOld={queryParams.priceOld}
              freeTrial={toBoolean(queryParams.freeTrial)}
            />
          </Col>
        </Row>
      </SubscribePageTemplate>
    </WebviewLayout>
  );
};

export default AssinaturaAppPage;

export const query = graphql`
  query AssinaturaAppQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
